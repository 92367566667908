<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content>
    <sz-text-box
        [formControl]="message"
        [required]="true"
        [placeholder]="data.placeholder"
        rows="4"
    />
    <sz-dialog-button-bar
        primaryText="common.send"
        [primaryDisabled]="message.value.length === 0"
        (primaryClick)="saveFeedback()"
        (secondaryClick)="dialogRef.close()"
    />
</div>
